import React from 'react';
import 'antd/dist/antd.css';
import './index.css';
import { Layout, Result } from 'antd';
import JYHeader from './component/header';
import JYFooter from './component/footer';

const { Header, Content, Footer } = Layout;

const App = () => {
    document.title = "上海绝影网络科技有限公司"
    return (
        <div>
            <JYHeader></JYHeader>
            <Result
                status="500"
                title="敬请期待"
                subTitle="网站建设中……"
            />
            <JYFooter></JYFooter>
        </div>

    )
    // <Layout className="layout">
    //     <Header className='header'>

    //     </Header>
    //     <Content>

    //     </Content>
    //     <Footer className='footer'>

    //     </Footer>
    // </Layout>
};

export default App;