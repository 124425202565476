import { Menu } from 'antd';
import React, { useState } from 'react';
import './index.css';

const items = [
    {
        label: '上海绝影网络科技有限公司',
        key: 'top',
    },

];

function JYHeader() {
    const [current, setCurrent] = useState('mail');

    const onClick = (e) => {
        console.log('click ', e);
        setCurrent(e.key);
    };
    return (
        <div className='jyheader-container'>
            <div className='jyheader-logo-container'>
                <img src='nav_logo.svg' alt='sss'/>
            </div>
            <div className='jyheader-menu-container'>
                <Menu onClick={onClick} selectedKeys={[current]} mode="horizontal" items={items}></Menu>
            </div>
        </div>
    )
}

export default JYHeader