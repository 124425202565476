import React, { useState } from 'react';
import './index.css';

function JYFooter() {

    return (
        <div className='jyfooter-container'>
            <div className='jyfooter-info-container'>
                <p>上海绝影网络科技有限公司</p>
                <p>公司地址： 上海市杨浦区淞沪路98号平盛大厦1306-1 邮箱：support@jooying.com</p>
                <p>版权所有@上海绝影网络科技有限公司 · <a href='https://beian.miit.gov.cn/#/Integrated/index'>沪ICP备2022007497号-1</a></p>
            </div>
            <div className='jyfooter-logo-container'>
                <img src='footer_logo.svg' alt='logo' />
            </div>
        </div>
    )
}

export default JYFooter